import { Link } from "react-router-dom";

import ImgExpand from "../../assets/images/expand.png";
import ImgCollapse from "../../assets/images/collapse.png";

interface CollapseControlProps {
  title: string;
  desc: string;
  noCollapse?: boolean;
  openCollapse: () => void;
  closeCollapse: () => void;
}

const CollapseControl = ({
  title,
  desc,
  noCollapse,
  openCollapse,
  closeCollapse,
}: CollapseControlProps) => {
  return (
    <>
      <div
        className={`flex ${noCollapse ? `justify-center mb-2` : `justify-between`}  items-center`}
      >
        <div>
          <h3 className="text-left text-[#F00] text-sm">
            <b>{title}</b> {desc}
          </h3>
        </div>
        {!noCollapse && (
          <div className="flex justify-center items-center gap-1">
            <small>
              <b>ALL:</b>
            </small>{" "}
            <Link to={``} title="Expand All" onClick={() => openCollapse()}>
              <img src={ImgExpand} alt="" />
            </Link>{" "}
            <Link to={``} title="Collapse All" onClick={() => closeCollapse()}>
              <img src={ImgCollapse} alt="" />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default CollapseControl;
