import BoxDesc from "../BoxDesc";
import BoxAlertContainer from "../BoxAlertContainer";
import AlertItem from "../AlertItem";
import AlertTitle from "../AlertTitle";
import SearchForm from "../SearchForm";
import { Sign } from "../../_type/Sign";
import { useState, useEffect } from "react";
import auth from "../../../../utils/authConstants";
import secureLocalStorage from "react-secure-storage";

const BoxSigns = () => {
  const storeNumber: string | null = window.localStorage.getItem("storeNumber");
  const [signsSummary, setSignsSummary] = useState<any>(null);

  useEffect(() => {
    const fetchSignsData = async () => {
      if (!storeNumber) return;
      try {
        const response = await fetch(`${auth.REACT_APP_SERVER_API_URL}/${storeNumber}/signs/get-summary`, {
          headers: {
            "Authorization": `Bearer ${secureLocalStorage.getItem("access_token")}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setSignsSummary(data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSignsData();
  }, [storeNumber]);


  const circCount = signsSummary?.circular || 0;
  const permCount = signsSummary?.permanent || 0;
  const safetyCount = signsSummary?.safety || 0;
  const miscCount = signsSummary?.misc || 0;
  const count = circCount + permCount + safetyCount + miscCount;
  return (
    <>
      <div className="mt-[10px] flex-col border-t border-t-[#CCC]">
        <div className="flex border-b border-[#CCC] flex-row px-1 py-[2px] justify-between text-xs">
          <BoxDesc />
          <div className="flex flex-col">
            <BoxAlertContainer>
              <AlertTitle title={`There are ${count} New Signs!`} />
              <AlertItem title="Circular & Deals" itemCnt={circCount} url="/signs_circular" sameWindow={true} />
              <AlertItem title="Permanent" itemCnt={permCount} url="/signs_permanent" sameWindow={true} />
              <AlertItem title="Lp/Safety" itemCnt={safetyCount} url="/signs_lp_safety"  sameWindow={true} />
              <AlertItem title="Miscellaneous" itemCnt={miscCount} url="/signs_misc" sameWindow={true} />
            </BoxAlertContainer>

            <BoxAlertContainer>
              <AlertTitle title={`Sign Walkthrus`} />
              {signsSummary?.signWalkThrus && signsSummary.signWalkThrus.length > 0 ? (
                signsSummary.signWalkThrus.map((walkthru: Sign, index) => (
                  <AlertItem
                    key={index}
                    title={walkthru?.storeNumber?.toUpperCase() + '.pdf'}
                    itemCnt={1}
                    url={walkthru.link}
                    isPdf={true}
                    sameWindow={false}
                    isNew={signsSummary.new}
                  />
                ))
              ) : (
                <div className="flex justify-center items-center h-14 border border-gray-300 rounded-md bg-gray-100">
                  <strong>Not Available</strong>
                </div>
              )}
            </BoxAlertContainer>
          </div>
        </div>
        {storeNumber ? (
          <SearchForm storeId={storeNumber} />
        ) : (
          <div>Please provide a store number to search.</div>
        )}
      </div>
    </>
  );
};

export default BoxSigns;
