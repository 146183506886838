import Header from "../../components/Header";
import HeaderContainer from "../../components/HeaderContainer";
import BoxStoreNumber from "./_components/BoxStoreNumber";

const StartPage = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <BoxStoreNumber />
    </>
  );
};

export default StartPage;
