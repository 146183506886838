/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { createContext, useContext, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { generateCodeChallenge, generateCodeVerifier } from '../utils/auth/pkceHelper';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();
const client_id = window.env.REACT_APP_CLIENT_ID;
const redirect_uri = window.env.REACT_APP_REDIRECT_URI;

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
  });

  const checkTokenExpiration = async () => {
    const accessToken = String(secureLocalStorage.getItem('access_token'));
    const refreshToken = secureLocalStorage.getItem('refresh_token');

    if (!accessToken || !refreshToken) {
      setAuthState({
        isAuthenticated: false,
      });
      login();
      return;
    }

    const decodedAccessToken = jwtDecode(accessToken);

    const currentTime = Math.floor(Date.now() / 1000);

    //use refresh token to fetch new access token if it is expired
    if (decodedAccessToken.exp < currentTime) {
      try {
        const newAccessToken = await refreshAccessToken(refreshToken);
        secureLocalStorage.removeItem('access_token');
        secureLocalStorage.setItem('access_token', newAccessToken);
      } catch (error) {
        console.error('Error refreshing access token:', error);
        login();
      }
    }
  };

  const refreshAccessToken = async (refreshToken) => {
    const token_url = window.env.REACT_APP_TOKEN_URL;
    const params = {
      client_id: client_id,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    };

    try {
      const response = await fetch(token_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(params),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error refreshing access token:', error);
      login();
    }
  };

  //exchange authorization code for tokens
  const handleAuthorization = async (code) => {
    const codeVerifier = secureLocalStorage.getItem('codeVerifier');
    const token_url = window.env.REACT_APP_TOKEN_URL;
    const params = {
      client_id: client_id,
      code: code,
      redirect_uri: redirect_uri,
      grant_type: 'authorization_code',
      code_verifier: codeVerifier,
    };

    try {
      const response = await fetch(token_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(params),
      });
      const tokens = await response.json();

      if(tokens.access_token != null) {
        secureLocalStorage.setItem('access_token', tokens.access_token);
        secureLocalStorage.setItem('id_token', tokens.id_token);
        secureLocalStorage.setItem('refresh_token', tokens.refresh_token);
      }

      setAuthState({
        isAuthenticated: true,
      });
    } catch (error) {
      console.error('Authentication error:', error);
    }
  };

  const login = async () => {
    try {
      const codeVerifier = generateCodeVerifier();
      const authorization_base_url = window.env.REACT_APP_AUTHORIZATION_BASE_URL;
      const scopes = window.env.REACT_APP_SCOPE;
      secureLocalStorage.setItem('codeVerifier', codeVerifier);

      const codeChallenge = await generateCodeChallenge(codeVerifier);
      const authUrl =
        authorization_base_url +
        '?client_id=' +
        client_id +
        '&redirect_uri=' +
        redirect_uri +
        '&response_type=code&scope=' +
        encodeURIComponent(scopes) +
        '&code_challenge_method=S256&code_challenge=' +
        codeChallenge;

      window.location.href = authUrl;
    } catch (error) {
      console.error('Error logging in: ', error);
      throw error;
    }
  };

  const logout = async () => {
    const token_revoke_url = window.env.REACT_APP_REVOKE_URL;
    const end_session_url = window.env.REACT_APP_END_SESSION;
    const app_url = window.env.REACT_APP_APP_URI;
    const encodedClientId = btoa(client_id);
    const params = {
      token: secureLocalStorage.getItem('access_token'),
    };
    //invalidate token - this revokes token only for this app
    try {
      await fetch(token_revoke_url, {
        method: 'POST',
        headers: {
          'X-XSRF-Header': 'PingFederate',
          Authorization: 'Basic ' + encodedClientId,
        },
        body: new URLSearchParams(params),
      });

      secureLocalStorage.removeItem('codeVerifier');
      secureLocalStorage.removeItem('access_token');
      secureLocalStorage.removeItem('id_token');
      secureLocalStorage.removeItem('refresh_token');

      //remove user session - this logs out all SSO apps
      window.location.href = end_session_url + '?TargetResource=' + app_url;
    } catch (error) {
      console.error('Authentication error:', error);
    }
  };

  const value = {
    authState,
    handleAuthorization,
    logout,
    login,
    checkTokenExpiration,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
