import { BrowserRouter, Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";

import StartPage from "./pages/StartPage";
import Welcome from "./pages/Welcome";
import Plano from "./pages/Plano";
import PlanoInPogs from "./pages/PlanoInPogs";
import PlanoOffPogs from "./pages/PlanoOffPogs";
import PlanoInsDocs from "./pages/PlanoInsDocs";
import Signs from "./pages/Signs";
import SignsCircular from "./pages/SignsCircular";
import SignsPermanent from "./pages/SignsPermanent";
import SignsLpSafety from "./pages/SignsLpSafety";
import SignsMisc from "./pages/SignsMisc";
import SignsActive from "./pages/SignsActive";
import SignsExpired from "./pages/SignsExpired";
import Rebates from "./pages/Rebates";
import { AuthProvider, useAuth } from './context/AuthContext';
import { Callback } from './pages/Callback';
import { Starc } from '@az/starc-ui';
import NavBar from './components/navBanner';
import * as PropTypes from 'prop-types';
import Download from './components/Download'

import "./App.css";

const MainLayout = () => (
  <Starc colorMode="light">
    <NavBar />
    <Outlet />
  </Starc>
);

const ProtectedRoute = ({ element }) => {
  const { authState, login } = useAuth();
  const location = useLocation();
  localStorage.setItem("from_location", location.pathname);
  localStorage.setItem("from_search_params", location.search);
  if (!authState.isAuthenticated) {
    login();
  }
  return element;
};

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="/callback" element={<Callback />} />
            <Route index element={<Navigate to="/store" />} />
            <Route path="/store" element={<ProtectedRoute element={<StartPage />} />} />
            <Route path="/welcome" element={<ProtectedRoute element={<Welcome />} />} />
            <Route path="/planogram_floor" element={<ProtectedRoute element={<Plano />} />} />
            <Route
              path="/planogram_floor_in_pogs"
              element={<ProtectedRoute element={<PlanoInPogs />} />} />
            <Route
              path="/planogram_floor_off_pogs"
              element={<ProtectedRoute element={<PlanoOffPogs />} />} />
            <Route
              path="/planogram_floor_ins_docs"
              element={<ProtectedRoute element={<PlanoInsDocs />} />} />
            <Route path="/signs" element={<ProtectedRoute element={<Signs  />} />} />
            <Route path="/signs_circular" element={<ProtectedRoute element={<SignsCircular />} />} />
            <Route path="/signs_permanent" element={<ProtectedRoute element={<SignsPermanent />} />} />
            <Route path="/signs_lp_safety" element={<ProtectedRoute element={<SignsLpSafety />} />} />
            <Route path="/signs_misc" element={<ProtectedRoute element={<SignsMisc />} />} />
            <Route path="/signs_active" element={<ProtectedRoute element={<SignsActive />} />} />
            <Route path="/signs_expired" element={<ProtectedRoute element={<SignsExpired />} />} />
            <Route path="/rebates" element={<ProtectedRoute element={<Rebates />} />} />
            <Route path="/download" element={<ProtectedRoute element={<Download />} />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
