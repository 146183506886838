const {
    REACT_APP_SERVER_API_URL,
    REACT_APP_CLIENT_ID,
    REACT_APP_SCOPE,
    REACT_APP_REDIRECT_URI,
    REACT_APP_AUTHORIZATION_BASE_URL,
    REACT_APP_TOKEN_URL,
    REACT_APP_REVOKE_URL,
    REACT_APP_USERINFO_URL,
    REACT_APP_END_SESSION,
    REACT_APP_APP_URI
} = window.env;

const auth = {
    REACT_APP_SERVER_API_URL,
    REACT_APP_CLIENT_ID,
    REACT_APP_SCOPE,
    REACT_APP_REDIRECT_URI,
    REACT_APP_AUTHORIZATION_BASE_URL,
    REACT_APP_TOKEN_URL,
    REACT_APP_REVOKE_URL,
    REACT_APP_USERINFO_URL,
    REACT_APP_END_SESSION,
    REACT_APP_APP_URI
}

export default auth;