import { View } from "@az/starc-ui";
import auth from "../../utils/authConstants.js"
import secureLocalStorage from "react-secure-storage"
import { useSearchParams } from "react-router-dom";

const Download = () => {

    const [searchParams, ] = useSearchParams();
    const handlePdfFetch = (filePath) => {
      const url = `${auth.REACT_APP_SERVER_API_URL}/get-storage-file?fileName=${filePath}`;
      fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${secureLocalStorage.getItem("access_token")}`,
          'Accept': 'application/pdf'
        }
      })
        .then(response => {
          if (!response.ok) {
            console.error('Response error:', response);
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const blobUrl = URL.createObjectURL(blob);
          window.location.href = blobUrl;
        })
        .catch(error => console.error('There was a problem with the fetch operation:', error));
    };
    handlePdfFetch(searchParams.get('fileName'));
    return <View/>
};

export default Download;