import { View, Button, Image } from '@az/starc-ui';
import { useAuth } from '../context/AuthContext';
const NavBar = () => {
  const {logout} = useAuth();
  return (
    <View
      padding={[4, 6]}
      height="100%"
      width="100%"
      direction="row"
      justify="space-between"
      attributes={{ style: { background: '#6e6e6e' }}}
    >
      <View direction="row">
        <View.Item>
          <Button href="/">
            <Image src="./images/autozone-logo.png" />
          </Button>
        </View.Item>
      </View>
      <View direction="row" gap={3}>
        <View direction="column" gap={1} />
        <View>
          <View.Item>
            <Button
              attributes={{ style: { background: 'black', height: 40, width: 150, color: 'white', fontSize: 20 } }} 
              onClick={logout}>Logout</Button>
          </View.Item>
        </View>
      </View>
    </View>
  );
};

export default NavBar;
