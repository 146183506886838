import HeaderContainer from "../../components/HeaderContainer";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import ContentWrapper from "../../components/ContentWrapper";
import SubNavbarSign from "../../components/SubNavbarSign";
import BoxSignsPermanent from "./_components/BoxSignsPermanent";

const SignsPermanent = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
        <Navbar idx={2} />
      </HeaderContainer>

      <ContentWrapper>
        <SubNavbarSign idx={2} />
        <BoxSignsPermanent />
      </ContentWrapper>
    </>
  );
};

export default SignsPermanent;
