import HeaderContainer from "../../components/HeaderContainer";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import ContentWrapper from "../../components/ContentWrapper";
import SubNavbarPlano from "../../components/SubNavbarPlano";
import BoxPlano from "./_components/BoxPlano";

const Planograms = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
        <Navbar idx={1} />
      </HeaderContainer>

      <ContentWrapper>
        <SubNavbarPlano idx={0} />
        <BoxPlano />
      </ContentWrapper>
    </>
  );
};

export default Planograms;
