import { useEffect, useState } from "react";
import TableStd from "../../../../components/TableStd";
import ImgNewBlink from "../../../../assets/images/new_blink.gif";
import auth from '../../../../utils/authConstants';
import secureLocalStorage from "react-secure-storage";

interface RebateType {
  id: number;
  link: string;
  description: string | null;
  deal: string | null;
  startDate: string;
  expDate: string;
  new: boolean;
}

const BoxRebates = () => {
  const [rebates, setRebate] = useState<RebateType[]>([]);

  const formatDate = (dateString: string) => {
    return dateString.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2-$3-$1')
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${auth.REACT_APP_SERVER_API_URL}/get-rebates`,
          {
            headers: {
              "Authorization": `Bearer ${secureLocalStorage.getItem("access_token")}`
            }
          }
        );
        const data = await response.json();
        const signDataArray: RebateType[] = Object.values(data);
        const rebateData: RebateType[] = signDataArray || [];
        setRebate(rebateData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="p-4">
      <TableStd
        noBorder={true}
        titles={[
          { title: "Product Description", align: "left" },
          { title: "Rebate Offer", align: "center" },
          { title: "Start Date", align: "center", width: 250 },
          { title: "Expiration Date", align: "center", width: 250 },
        ]}
      >
        {rebates.map(rebate => (
          <tr key={rebate.id} className={`border-b border-b-[#CCC] px-1 py-2 text-xm ${rebate.new ? 'bg-[#FEE]' : ''}`}>
            <td className="flex items-center text-xm">
              {rebate.new !== true ? (
                ""
              ) : (
                <img src={ImgNewBlink} alt="New" className="mr-2" />
              )}
              <button
                onClick={() => {
                  const url = `${auth.REACT_APP_SERVER_API_URL}/get-rebate-file?fileName=${rebate.id}.pdf`;
                  fetch(url, {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${secureLocalStorage.getItem("access_token")}`, 
                    }
                  })
                    .then(response => {
                      if (!response.ok) throw new Error('Network response was not ok');
                      return response.blob();
                    })
                    .then(blob => {
                      const blobUrl = URL.createObjectURL(blob);
                      window.open(blobUrl, '_blank');
                    })
                    .catch(error => console.error('There was a problem with the fetch operation:', error));
                }}
                className="text-blue-600 no-underline text-sm"
              >
                {rebate.description}
              </button>
            </td>

            <td className="text-center text-sm p-1">
              {decodeURIComponent(rebate.deal || "")}
            </td>
            <td className="text-center text-sm p-1">
              {formatDate(rebate.startDate)}
            </td>
            <td className="text-center text-sm p-1">
              {formatDate(rebate.expDate)}
            </td>
          </tr>
        ))}
      </TableStd>
    </div>
  );
};

export default BoxRebates;
