const BoxDesc = () => {
  return (
    <>
      <div className="text-[#666]">
        <b>With this Signs tool, you can...</b>
        <ul
          className="ml-[25px]"
          style={{ listStyleImage: "url(images/bullet.png)" }}
        >
          <li>Preview signs before printing them.</li>
          <li>
            Browse signs by category (Circular signs, General Sales Floor signs,
            etc).
          </li>
          <li>Browse all active signs sorted by placement.</li>
          <li>Check when signs are set to expire.</li>
          <li>View a list of recently expired signs.</li>
          <li>
            Verify that a sign has been viewed, when it was viewed last and how
            many times total it has been viewed.
          </li>
          <li>
            Search for signs by stock, size, description, placement, SKU, part #
            or keyword.
          </li>
        </ul>
        <b>When searching by...</b>
        <ul
          className="ml-[25px]"
          style={{ listStyleImage: "url(images/bullet.png)" }}
        >
          <li>
            <u>Part Number</u>: leave out any dashes in the part number (e.g.
            G-453-7 should be keyed as "G4537").
          </li>
          <li>
            <u>Keyword</u>: generally the best option is to search by the brand
            name of the product you are trying to find a sign for (e.g. use the
            keyword "Lucas" to find a sign for Lucas Oil Stabilizer).
          </li>
        </ul>
        <b>Filtering signs:</b>
        <ul
          className="ml-[25px]"
          style={{ listStyleImage: "url(images/bullet.png)" }}
        >
          <li>
            Select a size and/or a stock from the drop down lists below and
            click "Go".
          </li>
          <li>
            The tool will return all of the signs that fit the criteria you
            selected.
          </li>
        </ul>
        <b>Sorting:</b>
        <ul
          className="ml-[25px]"
          style={{ listStyleImage: "url(images/bullet.png)" }}
        >
          <li>
            Signs in each category are sorted first by sign size, then by sign
            stock and finally by sign description.
          </li>
          <li>
            Under the 'All Signs' tab, the signs are sorted by placement then by
            sign description.
          </li>
        </ul>
      </div>
    </>
  );
};

export default BoxDesc;
