import { useEffect, useState } from "react";
import AlertItem from "../AlertItem";
import AlertTitle from "../AlertTitle";
import auth from "../../../../utils/authConstants"
import secureLocalStorage from "react-secure-storage";

export interface StoreSummary {
  inlinePogs: number;
  offshelfPogs: number;
  instructions: number;
  signs: number;
  signWalkThrus: number;
  rebates: number;
  inlineFloorPlanPath: string;
  offshelfFloorPlanPath: string;
  locationCodesPath: string;
  overstockPath: string;
}

const defaultStoreSummary: StoreSummary = {
  inlinePogs: 0,
  offshelfPogs: 0,
  instructions: 0,
  signs: 0,
  signWalkThrus: 0,
  rebates: 0,
  inlineFloorPlanPath: "",
  offshelfFloorPlanPath: "",
  locationCodesPath: "",
  overstockPath: ""
};

const BoxNewAlert = () => {
  const [storeSummary, setStoreSummary] = useState<StoreSummary>(defaultStoreSummary);
  const [, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStoreSummary = async () => {
      try {
        const storeId = window.localStorage.getItem("storeNumber");
        const response = await fetch(`${auth.REACT_APP_SERVER_API_URL}/${storeId}/get-summary`, {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${secureLocalStorage.getItem("access_token")}`
          }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch store summary");
        }
        const data: StoreSummary = await response.json();
        setStoreSummary(data);
      } catch (err) {
        const errorMessage = (err as Error).message || "Error fetching data";
        setError(errorMessage);
        console.error(errorMessage);
      }
    };
    fetchStoreSummary();
  }, []);

  return (
    <div className="w-[250px] border border-[#CCC] m-[5px] p-[5px]">
      <AlertTitle cnt={
        storeSummary.inlinePogs + storeSummary.offshelfPogs + 
        storeSummary.instructions + storeSummary.signs + 
        storeSummary.signWalkThrus + storeSummary.rebates 
      } />
      <AlertItem title="Inline POGs" itemCnt={storeSummary.inlinePogs} url="/planogram_floor_in_pogs" />
      <AlertItem title="Offshelf POGs" itemCnt={storeSummary.offshelfPogs} url="/planogram_floor_off_pogs" />
      <AlertItem title="Instructions & Documents" itemCnt={storeSummary.instructions} url="/planogram_floor_ins_docs" />
      <AlertItem title="Signs" itemCnt={storeSummary.signs} url="/signs" />
      <AlertItem title="Signs Walkthru" itemCnt={storeSummary.signWalkThrus} url="/signs" />
      <AlertItem title="Rebates" itemCnt={storeSummary.rebates} url="/rebates" />
    </div>
  );
};

export default BoxNewAlert;
