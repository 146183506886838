import BoxDesc from "../BoxDesc";
import BoxNewAlert from "../BoxNewAlert";

const BoxWelcome = () => {
  return (
    <>
      <div className="mt-[10px] flex-col border-t border-t-[#CCC]">
        <div className="bg-[#D8D8D8] w-full flex justify-center items-center border-b border-b-[#999]">
          <h4 className="m-0 p-1 text-[#666666] font-bold">
            Welcome to the Planogram &amp; Sign Tool!
          </h4>
        </div>
        <div className="flex border-b border-[#CCC] flex-row px-1 py-[2px] justify-between text-xs">
          <BoxDesc />
          <div>
            <BoxNewAlert />
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxWelcome;
