import SubNavItem from "./SubNavItem";

interface SubNavbarSignProps {
  idx: number;
}

const SubNavbarSign = ({ idx }: SubNavbarSignProps) => {
  return (
    <>
      <div className="border border-[#999] mb-[10px]">
        <ul
          className="block overflow-hidden w-full m-0 p-0"
          style={{ background: `url(images/sub_nav_def.png)` }}
        >
          <SubNavItem title="Signs Home" url="/signs" selected={idx === 0} />
          <SubNavItem
            title="Circular & Deals"
            url="/signs_circular"
            selected={idx === 1}
          />
          <SubNavItem
            title="Permanent"
            url="/signs_permanent"
            selected={idx === 2}
          />
          <SubNavItem
            title="Lp/Safety"
            url="/signs_lp_safety"
            selected={idx === 3}
          />
          <SubNavItem
            title="Miscellaneous"
            url="/signs_misc"
            selected={idx === 4}
          />
          <SubNavItem title="&nbsp;" url="" selected={idx === 5} />
          <SubNavItem
            title="Active Signs"
            url="/signs_active"
            selected={idx === 6}
          />
          <SubNavItem
            title="Expired Signs"
            url="/signs_expired"
            selected={idx === 7}
          />
        </ul>
      </div>
    </>
  );
};

export default SubNavbarSign;
