import HeaderContainer from "../../components/HeaderContainer";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import ContentWrapper from "../../components/ContentWrapper";
import SubNavbarPlano from "../../components/SubNavbarPlano";
import BoxPlanoOffPogs from "./_components/BoxPlanoOffPogs";

const PlanoOffPogs = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
        <Navbar idx={1} />
      </HeaderContainer>

      <ContentWrapper>
        <SubNavbarPlano idx={2} />
        <BoxPlanoOffPogs />
      </ContentWrapper>
    </>
  );
};

export default PlanoOffPogs;
