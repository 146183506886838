import HeaderContainer from "../../components/HeaderContainer";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import ContentWrapper from "../../components/ContentWrapper";
import SubNavbarSign from "../../components/SubNavbarSign";
import BoxSignsLpSafety from "./_components/BoxSignsLpSafety";

const SignsLpSafety = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
        <Navbar idx={2} />
      </HeaderContainer>

      <ContentWrapper>
        <SubNavbarSign idx={3} />
        <BoxSignsLpSafety />
      </ContentWrapper>
    </>
  );
};

export default SignsLpSafety;
