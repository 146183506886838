import { useEffect, useState } from "react";
import CollapseHeader from "../../../../components/CollapseHeader";
import TableStd from "../../../../components/TableStd";
import ApiService from "../../../../services/apiService";
import { PogsignType } from "../../../Plano/type/Pogsign";
import ImgNewBlink from "../../../../assets/images/new_blink.gif";
import secureLocalStorage from "react-secure-storage";
import auth from '../../../../utils/authConstants';

const BoxPlanoInsDocs = () => {
  const [instructionsDocs, setInstructionsDocs] = useState<PogsignType[]>([]);
  const [isInstructions, setIsInstructions] = useState(true);

  useEffect(() => {
    const fetchInstructionsDocs = async () => {
      try {
        const apiService = new ApiService(secureLocalStorage.getItem("access_token"));
        const res = await apiService.getInstructPlanograms();

        if (Array.isArray(res)) {
          setIsInstructions(res.length > 0);
          setInstructionsDocs(res as PogsignType[]);
        } else {
          console.error("Expected an array but received:", res);
          setIsInstructions(false);
        }
      } catch (err) {
        console.error("Error fetching instructions and documents:", err);
      }
    };

    fetchInstructionsDocs();
  }, []);

  return (
    <>
      <CollapseHeader
        title="Instructions & Documents"
        isCollapse={false}
        openCollapse={() => { }}
        closeCollapse={() => { }}
        isNotCollapsable={true}
      >
        <TableStd
          titles={[
            { title: "File ID", align: "left", width: 125 },
            { title: "Description & Link", align: "left" },
            { title: "Arrival Date", width: 125 },
          ]}
        >
          {isInstructions === false ? (
            <tr>
              <td colSpan={4} height={50} align="center">
                <b>There are NO Instructions & Documents at this time.</b>
              </td>
            </tr>
          ) : (

            instructionsDocs.map((doc) => (
              <tr key={doc.id} className="text-sm">
                <td className="border-b border-b-[#CCC] px-1 py-[2px]">
                  {doc.newFlag && <img src={ImgNewBlink} alt="" />}
                  {doc.link?.split('/').pop()?.replace('.pdf', '').toUpperCase()}
                </td>
                <td className="border-b border-b-[#CCC] px-1 py-[2px]">
                  <button
                    onClick={() => {
                      const token = secureLocalStorage.getItem("access_token");
                      fetch(`${auth.REACT_APP_SERVER_API_URL}/get-storage-file?fileName=${doc.link}`, {
                        method: 'GET',
                        headers: {
                          'Authorization': `Bearer ${token}`,
                        },
                      })
                        .then(response => {
                          if (!response.ok) {
                            throw new Error('Network response was not ok');
                          }
                          return response.blob();
                        })
                        .then(blob => {
                          const blobUrl = URL.createObjectURL(blob);
                          window.open(blobUrl, '_blank');
                        })
                        .catch(error => console.error('There was a problem with the fetch operation:', error));
                    }}
                    title="You MUST print this POG from SMS to clear highlight from SMS menu screen."
                    className="text-[#06C] no-underline cursor-pointer"
                  >
                    {doc.description}
                  </button>
                </td>

                <td align="center" className="border-b border-b-[#CCC] px-1 py-[2px]">
                  {doc.arrivalDate}
                </td>
              </tr>
            )))}
        </TableStd>
      </CollapseHeader>
    </>
  );
};

export default BoxPlanoInsDocs;
