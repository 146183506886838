/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

const sha256 = (buffer) => {
  return crypto.subtle.digest('SHA-256', buffer);
};

const base64UrlEncode = (arrayBuffer) => {
  let string = String.fromCharCode.apply(null, new Uint8Array(arrayBuffer));
  return btoa(string)
    .replace(/\+/g, '-') // Convert '+' to '-'
    .replace(/\//g, '_') // Convert '/' to '_'
    .replace(/=+/, ''); // Remove ending '='
};

export const generateCodeVerifier = () => {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return base64UrlEncode(array);
};

export const generateCodeChallenge = async (codeVerifier) => {
  const hashed = await sha256(new TextEncoder().encode(codeVerifier));
  return base64UrlEncode(hashed);
};
