import HeaderContainer from "../../components/HeaderContainer";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import ContentWrapper from "../../components/ContentWrapper";
import SubNavbarSign from "../../components/SubNavbarSign";
import BoxSigns from "./_components/BoxSigns";

const Signs = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
        <Navbar idx={2} />
      </HeaderContainer>

      <ContentWrapper>
        <SubNavbarSign idx={0} />
        <BoxSigns />
      </ContentWrapper>
    </>
  );
};

export default Signs;
