import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const BoxStoreNumber = () => {
  const prevStore = localStorage.getItem("storeNumber") || "";
  const [storeNumber, setStoreNumber] = useState<any>(prevStore)
  const navigate = useNavigate();
  
  const handleGo = () => {
    if (! storeNumber || storeNumber.trim().length === 0 || isNaN(storeNumber)) {
      alert("Invalid store number");
      return;
    }
    localStorage.setItem("storeNumber", storeNumber.toString().replace(/^0+/, ''));
    navigate("/welcome");
  };

  return (
    <>
      <div className="flex justify-center items-center mt-[10px]">
        <div className="w-[400px]">
          <div className="flex justify-center items-center border-t border-t-[#ccc] border-b border-b-[#999] bg-[#D8D8D8]">
            <h4 className="m-0 p-1 text-[#666] font-bold">
              Please Enter a Store Number
            </h4>
          </div>
          <div className="flex items-center justify-center border-b border-b-[#CCC] p-1 text-sm text-[#666] gap-1">
            <b>Store #:</b>
            <input
              type="text"
              name="store"
              value={storeNumber}
              onChange={(e) => setStoreNumber(e.target.value)}
              className="border border-[#aaa] border-l-[#777] border-r-[#777] m-[2px] p-[3px] focus:outline-0 bg-white"
              style={{ background: `url(images/input_shadow.png) no-repeat` }}
            />
            <input
              type="submit"
              name="submit"
              value="GO"
              className="border-[3px] border-double border-[#999] border-l-[#CCC] border-t-[#CCC] text-[#333] font-bold text-sm m-[2px] p-[2px] bg-[#FAFAFA]"
              style={{ background: `url(images/button.png) no-repeat` }}
              onClick={() => handleGo()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxStoreNumber;
