import { useState, useEffect } from "react";

import ApiService from "../../../../services/apiService";

import CollapseControl from "../../../../components/CollapseControl";
import CollapseHeader from "../../../../components/CollapseHeader";
import TableStd from "../../../../components/TableStd";
import { PogsignType } from "../../../Plano/type/Pogsign";
import ImgNewBlink from "../../../../assets/images/new_blink.gif";
import secureLocalStorage from "react-secure-storage";
import auth from '../../../../utils/authConstants';

const BoxPlanoInPogs = () => {
  const [isCollapsed, setIsCollapsed] = useState<Record<string, boolean>>({});
  const [pogsigns, setPogsigns] = useState<Record<string, PogsignType[]>>({});
  const [sortedSigns, setSortedSigns] = useState<string[]>([]);
  const [uniquePogsNonMisc, setUniquePogsNonMisc] = useState(new Set());

  useEffect(() => {
    new ApiService(secureLocalStorage.getItem("access_token"))
      .getInlinePlanograms()
      .then((res: any) => {
        setPogsigns(res);
        const collapseState = Object.keys(res).reduce((acc, key) => {
          acc[key] = !res[key].some(pog => pog.newFlag);
          return acc;
        }, {});
        const keys = Object.keys(res).sort();
        keys.push(keys.splice(keys.indexOf("Miscellaneous"), 1)[0]);
        setSortedSigns(keys);
        setIsCollapsed(collapseState);
        const uniqueIdsFromNonMisc = new Set();
        Object.values(res).forEach(category => {
          if (category instanceof Array) {
            category.forEach(item => {
              if (item.group !== "Miscellaneous") {
                uniqueIdsFromNonMisc.add(item.id);
              }
            });
          }
        });
        setUniquePogsNonMisc(uniqueIdsFromNonMisc);      
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const toggleCollapse = (key: string) => {
    setIsCollapsed({ ...isCollapsed, [key]: !isCollapsed[key] });
  };

  const openAllCollapse = () => {
    setIsCollapsed(Object.keys(isCollapsed).reduce((acc, key) => ({ ...acc, [key]: false }), {}));
  };

  const closeAllCollapse = () => {
    setIsCollapsed(Object.keys(isCollapsed).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
  };

  return (
    <>
      <CollapseControl
        title="IMPORTANT:"
        desc="Printing these POGs in DOC does not replace
            printing POGs from SMS! You MUST print from SMS to clear highlight
            from SMS menu."
        openCollapse={openAllCollapse}
        closeCollapse={closeAllCollapse}
      />
      {sortedSigns.map((key) => (
        <CollapseHeader
          key={key}
          title={key.toUpperCase()}
          isCollapse={isCollapsed[key]}
          openCollapse={() => toggleCollapse(key)}
          closeCollapse={() => toggleCollapse(key)}
        >
          <TableStd
            titles={[
              { title: "POG ID", align: "left", width: 60 },
              { title: "POG Description & Link", align: "left" },
              { title: "Arrival Date", width: 125 },
              { title: "Date to be Completed", width: 125 },
            ]}
          >
            {pogsigns[key].map(pog => (
              // If group is not Miscellaneous, or group is Miscellaneous but item was not shown in other categories
              (key.toUpperCase() !== "MISCELLANEOUS" || ! uniquePogsNonMisc.has(pog.id)) && (
                <tr key={pog.id} className={`text-sm ${pog.newFlag ? 'bg-[#FEE]' : ''}`}>
                  <td className="border-b border-b-[#CCC] px-1 py-[2px] flex items-center gap-2">
                    {pog.newFlag && <img src={ImgNewBlink} alt="" />}
                    {pog.link?.split('/').pop()?.replace('.pdf', '').toUpperCase()}
                  </td>
                  <td className="border-b border-b-[#CCC] px-1 py-[2px]">
                    <button
                      onClick={() => {
                        const token = secureLocalStorage.getItem("access_token");
                        fetch(`${auth.REACT_APP_SERVER_API_URL}/get-storage-file?fileName=${pog.link}`, {
                          method: 'GET',
                          headers: {
                            'Authorization': `Bearer ${token}`,
                          },
                        })
                          .then(response => {
                            if (!response.ok) {
                              throw new Error('Network response was not ok');
                            }
                            return response.blob();
                          })
                          .then(blob => {
                            const blobUrl = URL.createObjectURL(blob);
                            window.open(blobUrl, '_blank');
                          })
                          .catch(error => console.error('There was a problem with the fetch operation:', error));
                      }}
                      title="You MUST print this POG from SMS to clear highlight from SMS menu screen."
                      className="text-[#06C] no-underline cursor-pointer"
                    >
                      {pog.description}
                    </button>
                  </td>
                  <td
                    align="center"
                    className="border-b border-b-[#CCC] px-1 py-[2px]"
                  >
                    {pog.arrivalDate}
                  </td>
                  <td
                    align="center"
                    className="border-b border-b-[#CCC] px-1 py-[2px]"
                  >
                    {pog.completionDate}
                  </td>
                </tr>)
            ))}
          </TableStd>
        </CollapseHeader>
      ))}
    </>
  );
};

export default BoxPlanoInPogs;
