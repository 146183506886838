/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { View } from '@az/starc-ui';

export const Callback = () => {
  const { handleAuthorization, login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const error = urlParams.get('error');

    if (code) {
      handleAuthorization(code)
        .then(() => {
          const redirectTo = localStorage.getItem("from_location") ? localStorage.getItem("from_location") + localStorage.getItem("from_search_params") : '/';
          navigate(redirectTo);
          // return <Navigate to={landingURL || PAGE_URLS.HOME} replace />;
        })
        .catch((error) => {
          console.error('Authentication error:', error);
        });
    } else if (error) {
      console.error('Authentication error:', error);
    } else {
      login();
    }
  }, [handleAuthorization, login, navigate]);
  return <View />;
};
