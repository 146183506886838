import HeaderContainer from "../../components/HeaderContainer";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import ContentWrapper from "../../components/ContentWrapper";
import BoxRebates from "./_components/BoxRebates";

const Rebates = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
        <Navbar idx={3} />
      </HeaderContainer>

      <ContentWrapper>
        <BoxRebates />
      </ContentWrapper>
    </>
  );
};

export default Rebates;
