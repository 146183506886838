import { useState, useEffect } from "react";

import CollapseControl from "../../../../components/CollapseControl";
import CollapseHeader from "../../../../components/CollapseHeader";
import TableStd from "../../../../components/TableStd";

import ApiService from "../../../../services/apiService";

import ImgNewBlink from "../../../../assets/images/new_blink.gif";
import { PogsignType } from "../../type/Pogsign"
import secureLocalStorage from "react-secure-storage";
import auth from '../../../../utils/authConstants';


const BoxPlano = () => {
  const [isInPlanoCollapsed, setIsInPlanoCollapsed] = useState(false);
  const [isOffPlanoCollapsed, setIsOffPlanoCollapsed] = useState(false);
  const [isNewInsCollapsed, setIsNewInsCollapsed] = useState(false);
  const [isNewInstruct, setIsNewInstruct] = useState(true);
  const [isNewInline, setIsNewInline] = useState(false);
  const [isNewOffline, setIsNewOffline] = useState(false);
  const [inlinePogs, setInlinePogs] = useState<PogsignType[]>([]);
  const [offshelfPogs, setOffshelfPogs] = useState<PogsignType[]>([]);
  const [instructPogs, setInstructPogs] = useState<PogsignType[]>([]);

  function uniqueData(data) {
    return data.reduce((acc, current) => {
      const existing = acc.find(item => item.id === current.id);
      if (!existing) {
        acc.push(current);
      }
      return acc;
    }, []);
  }

  useEffect(() => {
    new ApiService(secureLocalStorage.getItem("access_token"))
      .getNewPlanograms()
      .then((res: any) => {
        setInstructPogs(uniqueData(res.filter(pogsign => pogsign.category === "INSTRUCT")));
        setInlinePogs(uniqueData(res.filter(pogsign => pogsign.pogType === "INLINE" && pogsign.category !== "INSTRUCT")));
        setOffshelfPogs(uniqueData(res.filter(pogsign => pogsign.pogType === "OFFSHELF" && pogsign.category !== "INSTRUCT")));
        const newInstruct = res.some(pogsign => pogsign.category === "INSTRUCT" && pogsign.newFlag === true);
        const newInLineItems = res.some(pogsign => pogsign.pogType === "INLINE" && pogsign.category !== "INSTRUCT" && pogsign.newFlag === true);
        const newOffShelfItems = res.some(pogsign => pogsign.pogType === "OFFSHELF" && pogsign.category !== "INSTRUCT" && pogsign.newFlag === true);
        setIsNewInstruct(newInstruct);
        setIsNewInline(newInLineItems);
        setIsNewOffline(newOffShelfItems);
        setIsInPlanoCollapsed(!newInLineItems);
        setIsOffPlanoCollapsed(!newOffShelfItems);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const oepnAllCollapse = () => {
    setIsInPlanoCollapsed(false);
    setIsOffPlanoCollapsed(false);
    setIsNewInsCollapsed(false);
  };

  const closeAllCollapse = () => {
    setIsInPlanoCollapsed(true);
    setIsOffPlanoCollapsed(true);
    setIsNewInsCollapsed(true);
  };

  return (
    <>
      <CollapseControl
        title="IMPORTANT:"
        desc="Printing these POGs in DOC does not replace
            printing POGs from SMS! You MUST print from SMS to clear highlight
            from SMS menu."
        openCollapse={oepnAllCollapse}
        closeCollapse={closeAllCollapse}
      />

      <CollapseHeader
        title="New Inline Planograms"
        isCollapse={isInPlanoCollapsed}
        openCollapse={() => setIsInPlanoCollapsed(false)}
        closeCollapse={() => setIsInPlanoCollapsed(true)}
      >
        <TableStd
          titles={[
            { title: "POG ID", align: "left", width: 125 },
            { title: "POG Description & Link", align: "left" },
            { title: "Arrival Date", width: 125 },
            { title: "Date to be Completed", width: 125 },
          ]}
        >
          { isNewInline === false ? (
          <tr>
            <td colSpan={4} height={50} align="center">
              <b>There are NO new Inline Planograms at this time.</b>
            </td>
          </tr>
        ) : (
          inlinePogs.map((pogsign, idx) => (pogsign.newFlag === true && (
            <tr className={`text-sm ${pogsign.newFlag ? 'bg-[#FEE]' : ''}`} key={idx}>
              <td className="border-b border-b-[#CCC] px-1 py-[2px]">
                <div className="flex items-center gap-2">
                  {pogsign.newFlag && <img src={ImgNewBlink} alt="" />}{" "}
                  {pogsign.link?.split('/').pop()?.replace('.pdf', '').toUpperCase()}
                </div>
              </td>
              <td className="border-b border-b-[#CCC] px-1 py-[2px]">
                <button
                  onClick={() => {
                    const token = secureLocalStorage.getItem("access_token");
                    const url = `${auth.REACT_APP_SERVER_API_URL}/get-storage-file?fileName=${pogsign.link}`;

                    fetch(url, {
                      method: 'GET',
                      headers: {
                        'Authorization': `Bearer ${token}`,
                      },
                    })
                      .then(response => {
                        if (!response.ok) {
                          throw new Error('Network response was not ok');
                        }
                        return response.blob();
                      })
                      .then(blob => {
                        const blobUrl = URL.createObjectURL(blob);
                        window.open(blobUrl, '_blank');
                      })
                      .catch(error => console.error('There was a problem with the fetch operation:', error));
                  }}
                  title="You MUST print this POG from SMS to clear highlight from SMS menu screen."
                  className="text-[#06C] no-underline cursor-pointer"
                >
                  {pogsign.description}
                </button>
              </td>
              <td
                align="center"
                className="border-b border-b-[#CCC] px-1 py-[2px]"
              >
                {pogsign.arrivalDate}
              </td>
              <td
                align="center"
                className="border-b border-b-[#CCC] px-1 py-[2px]"
              >
                {pogsign.completionDate}
              </td>
            </tr>
          )
          )))}
        </TableStd>
      </CollapseHeader>

      <CollapseHeader
        title="New Offshelf Planograms"
        isCollapse={isOffPlanoCollapsed}
        openCollapse={() => setIsOffPlanoCollapsed(false)}
        closeCollapse={() => setIsOffPlanoCollapsed(true)}
      >
        <TableStd
          titles={[
            { title: "POG ID", align: "left", width: 125 },
            { title: "POG Description & Link", align: "left" },
            { title: "Arrival Date", width: 125 },
            { title: "Date to be Completed", width: 125 },
          ]}
        >
          { isNewOffline === false ? (
          <tr>
            <td colSpan={4} height={50} align="center">
              <b>There are NO new Offline Planograms at this time.</b>
            </td>
          </tr>
        ) : (
          offshelfPogs.map((pogsign, idx) => (pogsign.newFlag === true && (
            <tr className={`text-sm ${pogsign.newFlag ? 'bg-[#FEE]' : ''}`} key={idx}>
              <td className="border-b border-b-[#CCC] px-1 py-[2px]">
                <div className="flex items-center gap-2">
                  {pogsign.newFlag && <img src={ImgNewBlink} alt="" />}
                  { pogsign.link?.split('/').pop()?.replace('.pdf', '').toUpperCase()}
                </div>
              </td>
              <td className="border-b border-b-[#CCC] px-1 py-[2px]">
                <button
                  onClick={() => {
                    const token = secureLocalStorage.getItem("access_token");
                    const url = `${auth.REACT_APP_SERVER_API_URL}/get-storage-file?fileName=${pogsign.link}`;
                    fetch(url, {
                      method: 'GET',
                      headers: {
                        'Authorization': `Bearer ${token}`,
                      },
                    })
                      .then(response => {
                        if (!response.ok) {
                          throw new Error('Network response was not ok');
                        }
                        return response.blob();
                      })
                      .then(blob => {
                        const blobUrl = URL.createObjectURL(blob);
                        window.open(blobUrl, '_blank');
                      })
                      .catch(error => console.error('There was a problem with the fetch operation:', error));
                  }}
                  title="You MUST print this POG from SMS to clear highlight from SMS menu screen."
                  className="text-[#06C] no-underline cursor-pointer"
                >
                  {pogsign.description}
                </button>
              </td>
              <td
                align="center"
                className="border-b border-b-[#CCC] px-1 py-[2px]"
              >
                {pogsign.arrivalDate}
              </td>
              <td
                align="center"
                className="border-b border-b-[#CCC] px-1 py-[2px]"
              >
                {pogsign.completionDate}
              </td>
            </tr>
          )
          )))
          }
        </TableStd>
      </CollapseHeader>

      <CollapseHeader
        title="New Instructions & Documents"
        isCollapse={isNewInsCollapsed}
        openCollapse={() => setIsNewInsCollapsed(false)}
        closeCollapse={() => setIsNewInsCollapsed(true)}
      >
        <TableStd
          titles={[
            { title: "File ID", align: "left", width: 125 },
            { title: "Description & Link", align: "left" },
            { title: "Arrival Date", width: 125 },
          ]}
        >
        { isNewInstruct === false ? (
          <tr>
            <td colSpan={4} height={50} align="center">
              <b>There are NO new Instructions &amp; Documents at this time.</b>
            </td>
          </tr>
        ) : (
          instructPogs.map((pogsign, idx) => (
            pogsign.newFlag === true && (
              <tr className={`text-sm ${pogsign.newFlag ? 'bg-[#FEE]' : ''}`} key={idx}>
                <td className="border-b border-b-[#CCC] px-1 py-[2px]">
                  <div className="flex items-center gap-2">
                    {pogsign.newFlag && <img src={ImgNewBlink} alt="" />}
                    {pogsign.link?.split('/').pop()?.replace('.pdf', '').toUpperCase()}
                  </div>
                </td>
                <td className="border-b border-b-[#CCC] px-1 py-[2px]">
                  <button
                    onClick={() => {
                      const token = secureLocalStorage.getItem("access_token");
                      const url = `${auth.REACT_APP_SERVER_API_URL}/get-storage-file?fileName=${pogsign.link}`;
                      fetch(url, {
                        method: 'GET',
                        headers: {
                          'Authorization': `Bearer ${token}`,
                        },
                      })
                        .then(response => {
                          if (!response.ok) {
                            throw new Error('Network response was not ok');
                          }
                          return response.blob();
                        })
                        .then(blob => {
                          const blobUrl = URL.createObjectURL(blob);
                          window.open(blobUrl, '_blank');
                        })
                        .catch(error => console.error('There was a problem with the fetch operation:', error));
                    }}
                    title="You MUST print this POG from SMS to clear highlight from SMS menu screen."
                    className="text-[#06C] no-underline cursor-pointer"
                  >
                    {pogsign.description}
                  </button>
                </td>
                <td align="center" className="border-b border-b-[#CCC] px-1 py-[2px]">
                  {pogsign.arrivalDate}
                </td>
              </tr>
            )
          ))
         )}
        </TableStd>
      </CollapseHeader>
    </>
  );
};

export default BoxPlano;
