import axios, { AxiosInstance } from "axios";
import { PogsignType } from "../pages/Plano/type/Pogsign"
import auth from "../utils/authConstants";

class ApiService {
  service: AxiosInstance;

  constructor(accessToken) {
    this.service = axios.create({
      baseURL: auth.REACT_APP_SERVER_API_URL,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
      },
    });
  }

  getNewPlanograms = () => {
    const storeNumber = window.localStorage.getItem("storeNumber");
    return new Promise((resolve, reject) => {
      this.service
        .get(`/${storeNumber}/planograms`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getInlinePlanograms = () => {
    const storeNumber = window.localStorage.getItem("storeNumber");
    return new Promise((resolve, reject) => {
      this.service
        .get(`/${storeNumber}/planograms`)
        .then((res) => {
          let data: PogsignType[] = res.data;
  
          data = data.filter((value: PogsignType) => value.pogType === "INLINE"); 
          const groupedData = data.reduce((acc, curr) => {
            const groupKey = curr.group || "Miscellaneous";
            if (!acc[groupKey]) {
              acc[groupKey] = [];
            }
            acc[groupKey].push(curr);
            return acc;
          }, {} as Record<string, PogsignType[]>);

  
          resolve(groupedData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getOffshelfPlanograms = () => {
    const storeNumber = window.localStorage.getItem("storeNumber");
    return new Promise((resolve, reject) => {
      this.service
        .get(`/${storeNumber}/planograms`)
        .then((res) => {
          let data: PogsignType[] = res.data;
  
          data = data.filter((value: PogsignType) => value.pogType === "OFFSHELF"); 
          const groupedData = data.reduce((acc, curr) => {
            const groupKey = curr.group || "Miscellaneous";
            if (!acc[groupKey]) {
              acc[groupKey] = [];
            }
            acc[groupKey].push(curr);
            return acc;
          }, {} as Record<string, PogsignType[]>);

  
          resolve(groupedData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getInstructPlanograms = () => {
    const storeNumber = window.localStorage.getItem("storeNumber");
    return new Promise((resolve, reject) => {
      this.service
        .get(`/${storeNumber}/planograms`)
        .then((res) => {
          let data: PogsignType[] = res.data;
          data = data.filter((value: PogsignType) => value.category === "INSTRUCT");
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };


}

export default ApiService;
