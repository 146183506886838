import { useState } from "react";
import { Link } from "react-router-dom";

interface SubNavItemProps {
  title: string;
  url: string;
  selected: boolean;
  isPdf?: boolean;
}

const SubNavItem = ({ title, url, selected, isPdf }: SubNavItemProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <li className="border-r border-r-[#999999] inline float-left text-[10px] m-0">
        {isPdf ? (
          <a
            href={url}
            className={`block border-l p-[6px] font-bold ${selected ? `text-[#FFF] border-l-[#BE7C1E]` : `text-[#666] border-l-[#FFF] hover:text-[#666]`}  `}
            style={{
              background: `url(images/${selected ? `sub_nav_sel.png` : `${isHovered ? `sub_nav_hov.png` : `sub_nav_def.png`}`})`,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {title}
          </a>
        ) : (
          <Link
            to={url}
            className={`block border-l p-[6px] font-bold ${selected ? `text-[#FFF] border-l-[#BE7C1E]` : `text-[#666] border-l-[#FFF] hover:text-[#666]`}  `}
            style={{
              background: `url(images/${selected ? `sub_nav_sel.png` : `${isHovered ? `sub_nav_hov.png` : `sub_nav_def.png`}`})`,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {title}
          </Link>
        )}
      </li>
    </>
  );
};

export default SubNavItem;
