import { useState } from "react";
import { Link } from "react-router-dom";

interface NavItemProps {
  title: string;
  url: string;
  selected: boolean;
}

const NavItem = ({ title, url, selected }: NavItemProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <li
        className={`inline float-left mx-[1px] border-b p-0 ${selected ? `mt-0 border-b-[#FFF]` : `mt-[2px] -mb-[2px] border-b-[#CCC]`} `}
      >
        <Link
          to={url}
          className={`block font-bold hover:text-[#F90] ${selected ? `text-[#06C]` : `text-white no-underline`} `}
          style={{
            background: selected
              ? "url(images/new_sel_tab.png) no-repeat right -30px"
              : `url(images/${isHovered ? `new_hov_tab.png` : `new_def_tab.png`}) no-repeat right -30px`,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <span
            className="block mr-[9px] pl-[9px] whitespace-nowrap"
            style={{
              background: selected
                ? "url(images/new_sel_tab.png) no-repeat top left"
                : `url(images/${isHovered ? `new_hov_tab.png` : `new_def_tab.png`}) no-repeat top left`,
            }}
          >
            {title}
          </span>
        </Link>
      </li>
    </>
  );
};

export default NavItem;
