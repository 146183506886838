interface HeaderContainerProps {
  children: React.ReactNode;
}

const HeaderContainer = ({ children }: HeaderContainerProps) => {
  return (
    <>
      <div
        className="min-h-[75px]"
        style={{
          background: "url(images/nav_bkgd.png) repeat-x bottom",
        }}
      >
        {children}
      </div>
    </>
  );
};

export default HeaderContainer;
