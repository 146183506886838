import HeaderContainer from "../../components/HeaderContainer";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import ContentWrapper from "../../components/ContentWrapper";
import BoxWelcome from "./_components/BoxWelcome";

const Welcome = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
        <Navbar idx={0} />
      </HeaderContainer>

      <ContentWrapper>
        <BoxWelcome />
      </ContentWrapper>
    </>
  );
};

export default Welcome;
