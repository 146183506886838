interface AlertTitleProps {
  cnt: number;
}

const AlertTitle = ({ cnt }: AlertTitleProps) => {
  return (
    <>
      <div className="border-t border-t-[#CCC] text-[#666]">
        <p className="text-[#666] font-bold border-b border-b-[#999] bg-[#D8D8D8] p-1 m-0 text-center">
          There are {cnt} NEW Items!
        </p>
      </div>
    </>
  );
};

export default AlertTitle;
