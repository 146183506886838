interface AlertTitleProps {
  title: string;
}

const AlertTitle = ({ title }: AlertTitleProps) => {
  return (
    <>
      <div className="border-t border-t-[#CCC] text-[#666]">
        <p className="text-[#666] font-bold border-b border-b-[#999] bg-[#D8D8D8] p-1 m-0 text-center">
          {title}
        </p>
      </div>
    </>
  );
};

export default AlertTitle;
