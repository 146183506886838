import { useState, useEffect } from "react";
import { Sign } from "../../Signs/_type/Sign"; 
import auth from '../../../utils/authConstants'
import secureLocalStorage from "react-secure-storage";

export const useFetchSigns = (type: "ACTIVE" | "EXPIRED" | "CIRC" | "SAFE" | "PERM" | "MISC" ) => {
  const [signs, setSigns] = useState<Sign[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storeNumber = window.localStorage.getItem("storeNumber");
        const response = await fetch(`${auth.REACT_APP_SERVER_API_URL}/${storeNumber}/signs`,
          {
            headers: {
              "Authorization": `Bearer ${secureLocalStorage.getItem("access_token")}`
            }
          }
        );
        const data = await response.json();
        let signsData: Sign[] = [];
        if (type === "EXPIRED") {
          signsData = data.filter(sign => sign.active === false) || [];
        } else {
          signsData = data.filter(sign => sign.active === true) || [];
          if (type !== "ACTIVE") {
            signsData = signsData.filter(sign => sign.type === type);
          }
        }
        signsData.sort((a, b) => a.placement.localeCompare(b.placement));
        setSigns(signsData);
      } catch (err) {
        setError("Error fetching data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [type]);

  return { signs, loading, error };
};
