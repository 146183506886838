import { Link } from "react-router-dom";
import ImgNewBlink from "../../../../assets/images/new_blink.gif";
import auth from "../../../../utils/authConstants";
import secureLocalStorage from "react-secure-storage";

interface AlertItemProps {
  title: string;
  itemCnt: number;
  isPdf?: boolean;
  isOutLink?: boolean;
  url?: string;
  sameWindow?: boolean;
  isNew?: boolean;
}

const AlertItem = ({
  title,
  itemCnt,
  isPdf,
  isOutLink,
  url,
  sameWindow,
  isNew
}: AlertItemProps) => {
  const handleClick = (event: React.MouseEvent) => {
    if (isPdf && url && !isOutLink) {
      event.preventDefault();
      fetch(`${auth.REACT_APP_SERVER_API_URL}/get-storage-file?fileName=${url}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${secureLocalStorage.getItem("access_token")}`, 
        }
      })
        .then(response => {
          if (!response.ok) throw new Error('Network response was not ok');
          return response.blob(); 
        })
        .then(blob => {
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        })
        .catch(error => console.error('There was a problem with the fetch operation:', error));
    } else if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <div
      className={`${! isNew ? `bg-[#FFF]` : `bg-[#FEE]`} hover:bg-amber-50 text-[#666] flex justify-center items-center px-1 py-[2px] border-b border-b-[#CCC]`}
    >
      {(isPdf || isOutLink) && ! sameWindow ? (
        <a
          href={url ? url : "#"}
          onClick={handleClick}
          className="text-[#06C] font-bold flex items-center justify-center gap-1"
        >
          {isNew && <img src={ImgNewBlink} alt="" />}
          {title}
        </a>
      ) : (
        <Link
          to={url ? url : "#"}
          className="text-[#06C] font-bold flex items-center justify-center gap-1"
        >
          {itemCnt !== 0 && <img src={ImgNewBlink} alt="" />}
          {title} ({itemCnt})
        </Link>
      )}
    </div>
  );
};

export default AlertItem;
