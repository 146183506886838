import ImgCollapse from "../../assets/images/collapse.png";
import ImgExpand from "../../assets/images/expand.png";

interface CollapseHeaderProps {
  title: string;
  isCollapse: boolean;
  openCollapse: () => void;
  closeCollapse: () => void;
  isNotCollapsable?: boolean;
  children: React.ReactNode;
}

const CollapseHeader = ({
  title,
  isCollapse,
  openCollapse,
  closeCollapse,
  isNotCollapsable,
  children,
}: CollapseHeaderProps) => {
  return (
    <>
      <div>
        {isCollapse ? (
          <h4
            className="flex items-center gap-2 text-[#FFF] text-[14px] font-bold bg-[#666] px-[5px] py-[3px] mt-2 cursor-pointer"
            onClick={() => openCollapse()}
          >
            {!isNotCollapsable && (
              <img src={ImgExpand} alt="" onClick={() => openCollapse()} />
            )}{" "}
            {title}
          </h4>
        ) : (
          <h4
            className="flex items-center gap-2 text-[#FFF] text-[14px] font-bold bg-[#666] px-[5px] py-[3px] mt-2 cursor-pointer"
            onClick={() => closeCollapse()}
          >
            {!isNotCollapsable && (
              <img src={ImgCollapse} alt="" onClick={() => openCollapse()} />
            )}{" "}
            {title}
          </h4>
        )}
        <div className={`${isCollapse ? `hidden` : `block`}`}>{children}</div>
      </div>
    </>
  );
};

export default CollapseHeader;
