import NavItem from "./NavItem";

interface NavbarProps {
  idx: number;
}

const Navbar = ({ idx }: NavbarProps) => {
  return (
    <>
      <div className="text-[1.2rem] px-[10px]">
        <ul className="block list-none overflow-hidden w-full m-0 p-0">
          <NavItem title="Welcome" url="/welcome" selected={idx === 0} />
          <NavItem
            title="Planograms & Floorplans"
            url="/planogram_floor"
            selected={idx === 1}
          />
          <NavItem title="Signs" url="/signs" selected={idx === 2} />
          <NavItem title="Rebates" url="/rebates" selected={idx === 3} />
        </ul>
      </div>
    </>
  );
};

export default Navbar;
