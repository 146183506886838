import { Link } from "react-router-dom";

import ImgNewBlink from "../../../../assets/images/new_blink.gif";

interface AlertItemProps {
  title: string;
  itemCnt: number;
  url?: string;
}

const AlertItem = ({ title, itemCnt, url }: AlertItemProps) => {
  return (
    <>
      <div
        className={`${itemCnt === 0 ? `bg-[#FFF]` : `bg-[#FEE]`} text-[#666] flex justify-center items-center px-1 py-[2px] border-b border-b-[#CCC]`}
      >
        <Link
          to={url ? url : "#"}
          className="text-[#06C] font-bold flex items-center justify-center gap-1"
        >
          {itemCnt !== 0 && <img src={ImgNewBlink} alt=""/>}
          {title} ({itemCnt})
        </Link>
      </div>
    </>
  );
};

export default AlertItem;
