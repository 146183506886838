import React, { useState , useEffect } from "react";
import TableStd from "../../../../components/TableStd";
import ImgPrintNo from "../../../../assets/images/print_no.png";
import ImgPrintYes from "../../../../assets/images/print_yes.png";
import NoSignsFound from "../../../NoSignFound/_components/NoSignFound";
import { useFetchSigns } from "../../../Signs/_hook/useFetchSigns";
import ImgNewBlink from "../../../../assets/images/new_blink.gif";
import auth from '../../../../utils/authConstants';
import secureLocalStorage from "react-secure-storage";

const BoxSignsActive = () => {
  const { signs: fetchedSigns, loading, error } = useFetchSigns("ACTIVE");
  const [activeSigns, setActiveSigns] = useState(fetchedSigns);

  useEffect(() => {
    setActiveSigns(fetchedSigns);
  }, [fetchedSigns]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <TableStd
        noBorder={true}
        titles={[
          { title: "Sign Description", align: "left" },
          { title: "Size" },
          { title: "Sign Stock" },
          { title: "Placement" },
          { title: "Expires" },
          { title: "Viewed?", width: 50 },
        ]}
      >
        {activeSigns.length === 0 ? (
          <NoSignsFound colSpan={6} />
        ) : (
          activeSigns.map((sign, index) => (
            <tr key={index} className={`text-sm ${sign.new ? 'bg-[#FEE]' : ''}`}>
              <td className="border-b border-b-[#CCC] px-1 py-[2px] flex items-center">
                {! sign.new ? (
                  ""
                ) : (
                  <img src={ImgNewBlink} alt="New" className="mr-2" />
                )}
                <button
                  onClick={() => {
                    fetch(`${auth.REACT_APP_SERVER_API_URL}/get-storage-file?fileName=${sign.link}`, {
                      method: 'GET',
                      headers: {
                        'Authorization': `Bearer ${secureLocalStorage.getItem("access_token")}`, 
                      }
                    })
                      .then(response => {
                        if (!response.ok) throw new Error('Network response was not ok');
                        const updatedSigns = activeSigns.map(value => 
                          value.link === sign.link && value.description === sign.description 
                            ? { ...value, isClicked: true } 
                            : value
                        );
                        setActiveSigns(updatedSigns);
                        return response.blob(); 
                      })
                      .then(blob => {
                        const blobUrl = URL.createObjectURL(blob);
                        window.open(blobUrl, '_blank'); 
                      })
                      .catch(error => console.error('There was a problem with the fetch operation:', error));
                  }}
                  className="text-blue-600 no-underline text-sm"
                >
                  {sign.description}
                </button>
              </td>
              <td align="center" className="border-b border-b-[#CCC] px-1 py-[2px]">
                {sign.size}
              </td>
              <td align="center" className="border-b border-b-[#CCC] px-1 py-[2px]">
                {sign.stock}
              </td>
              <td align="center" className="border-b border-b-[#CCC] px-1 py-[2px]">
                {sign.placement}
              </td>
              <td align="center" className="border-b border-b-[#CCC] px-1 py-[2px]">
                {sign.expirationDate}
              </td>
              <td align="center" className="border-b border-b-[#CCC] px-1 py-[2px]">
                <img src={sign.isClicked ? ImgPrintYes : ImgPrintNo} alt="" />
              </td>
            </tr>
          ))
        )}
      </TableStd>
    </>
  );
};

export default BoxSignsActive;
