import * as React from "react";
import { Link } from "react-router-dom";

import ImgPrint from "../../assets/images/print_icon_sm.png";

const Header = () => {
  function handlePrint() {
    window.print()
  }

  return (
    <>
      <div className="flex justify-between">
        <div>
          <h1 className="text-[#666] text-[20px] font-bold m-0 pt-[5px] px-[10px]">
            Planogram &amp; Sign Tool
          </h1>
          <h2 className="text-[#999] text-[14px] font-bold m-0 px-[25px] pb-[5px]">
            Store: {localStorage.getItem("storeNumber")}
          </h2>
        </div>
        <div className="text-xs">
          <Link
            to={``}
            className="relative top-2 right-2 pt-1 text-[#06C] flex gap-[2px]"
            onClick={handlePrint}
          >
            <img src={ImgPrint} alt="" />
            Print
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
