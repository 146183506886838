interface TableTitleProps {
  title: string;
  align?: "center" | "left" | "right" | "justify" | "char" | undefined;
  width?: number;
}

interface TableStdProps {
  titles: TableTitleProps[];
  noBorder?: boolean;
  children: React.ReactNode;
}

const TableStd = ({ titles, noBorder, children }: TableStdProps) => {
  return (
    <>
      <div
        className={`${noBorder ? `border-none` : `border-2 border-[#666] border-t-none`}`}
      >
        <table
          width="100%"
          cellSpacing={0}
          className="border-t border-t-[#CCC] text-[#666]"
        >
          <tbody>
            <tr>
              {titles.map((title: TableTitleProps, idx) => (
                <th
                  align={title.align}
                  className={`text-[#666] font-bold border-b border-b-[#999] p-1 bg-[#D8D8D8] w-[${title.width}px]`}
                  key={idx}
                >
                  {title.title}
                </th>
              ))}
            </tr>
            {children}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableStd;
