import HeaderContainer from "../../components/HeaderContainer";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import ContentWrapper from "../../components/ContentWrapper";
import SubNavbarSign from "../../components/SubNavbarSign";
import BoxSignsActive from "./_components/BoxSignsActive";

const SignsActive = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
        <Navbar idx={2} />
      </HeaderContainer>

      <ContentWrapper>
        <SubNavbarSign idx={6} />
        <BoxSignsActive />
      </ContentWrapper>
    </>
  );
};

export default SignsActive;
