import React from 'react';

interface NoSignsFoundProps {
  colSpan: number;
  message?: string;
}

const NoSignsFound: React.FC<NoSignsFoundProps> = ({ colSpan, message }) => {
  return (
    <tr>
      <td
        colSpan={colSpan}
        height="100"
        align="center"
        className="border-b border-b-[#CCC] px-1 py-[2px]"
      >
        <b>{message || "Sorry. There were no signs found for your store in this category."}</b>
      </td>
    </tr>
  );
};

export default NoSignsFound;
