import ImgNewBlink from "../../../../assets/images/new_blink.gif";

const BoxDesc = () => {
  return (
    <>
      <div className="text-[#666]">
        <b>With this tool, you can...</b>
        <ul
          className="ml-[25px]"
          style={{ listStyleImage: "url(images/bullet.png)" }}
        >
          <li>
            Preview Inline &amp; Offshelf POGs in color before printing in SMS.
          </li>
          <li>Print selective pages.</li>
          <li>Analyze what it will take to set the POG.</li>
          <li>Zoom in to sections for better viewing of details.</li>
          <li>Quickly access your Store Layouts.</li>
        </ul>
        <b>Grouping &amp; Sorting...</b>
        <ul
          className="ml-[25px]"
          style={{ listStyleImage: "url(images/bullet.png)" }}
        >
          <li>
            Offshelf POGs are grouped by section (Front, Middle &amp; Back End
            Caps, Murfs, Checkout, etc.)
          </li>
          <li>All Planograms are sorted alphabetically by POG ID.</li>
          <li>Rebates are sorted by Product Description.</li>
        </ul>
        <b>New Items...</b>
        <ul
          className="ml-[25px]"
          style={{ listStyleImage: "url(images/bullet.png)" }}
        >
          <li>
            <span className="flex items-center">
              Have a red blinking light (
              <img className="mt-1" src={ImgNewBlink} alt=""/>) next to them.
            </span>
          </li>
          <li>
            <span>Are highlighted with a light red background.</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default BoxDesc;
