import SubNavItem from "./SubNavItem";
import auth from '../../utils/authConstants';
import secureLocalStorage from "react-secure-storage";

interface SubNavbarPlanoProps {
  idx: number;
}

const SubNavbarPlano = ({ idx }: SubNavbarPlanoProps) => {

  const storeNumber = window.localStorage.getItem("storeNumber") || "";
  const paddedStoreNumber = storeNumber.padStart(5, '0');

  const handlePdfFetch = (filePath) => {
    const url = `${auth.REACT_APP_SERVER_API_URL}/get-storage-file?fileName=${filePath}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${secureLocalStorage.getItem("access_token")}`,
        'Accept': 'application/pdf'
      }
    })
      .then(response => {
        if (!response.ok) {
          console.error('Response error:', response);
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      })
      .catch(error => console.error('There was a problem with the fetch operation:', error));
  };


  return (
    <>
      <div className="border border-[#999] mb-[10px]">
        <ul
          className="block overflow-hidden w-full m-0 p-0"
          style={{ background: `url(images/sub_nav_def.png)` }}
        >
          <SubNavItem
            title="New Planograms"
            url="/planogram_floor"
            selected={idx === 0}
          />
          <SubNavItem
            title="All Inline POGs"
            url="/planogram_floor_in_pogs"
            selected={idx === 1}
          />
          <SubNavItem
            title="All Offshelf POGs"
            url="/planogram_floor_off_pogs"
            selected={idx === 2}
          />
          <SubNavItem
            title="Instructions &amp; Documents"
            url="/planogram_floor_ins_docs"
            selected={idx === 3}
          />
          <SubNavItem title="&nbsp;" url="" selected={idx === 4} />
          <SubNavItem
            title="Inline Floorplan"
            onClick={() => handlePdfFetch(`store_doc/layouts/fpmdse/${paddedStoreNumber}fpmdse.pdf`)}
            selected={idx === 5}
            isPdf={true}
          />
          <SubNavItem
            title="Offshelf Floorplan"
            onClick={() => handlePdfFetch(`store_doc/layouts/fdpl/${paddedStoreNumber}fdpl.pdf`)}
            selected={idx === 6}
            isPdf={true}
          />
          <SubNavItem
            title="Location Codes"
            onClick={() => handlePdfFetch(`store_doc/layouts/fdloc/${paddedStoreNumber}fdloc.pdf`)}
            selected={idx === 7}
            isPdf={true}
          />
          <SubNavItem
            title="Perimeter Overstock Signs"
            onClick={() => handlePdfFetch(`store_doc/layouts/fpsign/${paddedStoreNumber}fpsign.pdf`)}
            selected={idx === 8}
            isPdf={true}
          />
        </ul>
      </div>
    </>
  );
};

export default SubNavbarPlano;
