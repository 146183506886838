interface ContentWrapperProps {
  children: React.ReactNode;
}

const ContentWrapper = ({ children }: ContentWrapperProps) => {
  return (
    <>
      <div className="p-[10px]">{children}</div>
    </>
  );
};

export default ContentWrapper;
