import HeaderContainer from "../../components/HeaderContainer";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import ContentWrapper from "../../components/ContentWrapper";
import SubNavbarSign from "../../components/SubNavbarSign";
import BoxSignsCircular from "./_components/BoxSignsCircular";

const SignsCircular = () => {
  return (
    <>
      <HeaderContainer>
        <Header />
        <Navbar idx={2} />
      </HeaderContainer>

      <ContentWrapper>
        <SubNavbarSign idx={1} />
        <BoxSignsCircular />
      </ContentWrapper>
    </>
  );
};

export default SignsCircular;
