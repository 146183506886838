interface BoxAlertContainerProps {
  children: React.ReactNode;
}

const BoxAlertContainer = ({ children }: BoxAlertContainerProps) => {
  return (
    <>
      <div className="w-[250px] border border-[#CCC] m-[5px] p-[5px]">
        {children}
      </div>
    </>
  );
};

export default BoxAlertContainer;
