import React, { useState } from "react";
import axios from "axios";
import TableStd from "../../../../components/TableStd";
import ImgPrintNo from "../../../../assets/images/print_no.png";
import ImgPrintYes from "../../../../assets/images/print_yes.png";
import { Sign } from "../../_type/Sign";
import ImgNewBlink from "../../../../assets/images/new_blink.gif";
import auth from '../../../../utils/authConstants'
import NoSignsFound from "../../../NoSignFound/_components/NoSignFound";
import secureLocalStorage from "react-secure-storage";

interface SearchFormProps {
  storeId: string;
}

const SearchForm: React.FC<SearchFormProps> = ({ storeId = "" }) => {
  const [searchStr, setSearchStr] = useState<string>("");
  const [sizeStr, setSizeStr] = useState<string>("");
  const [stockStr, setStockStr] = useState<string>("");
  const [signs, setSigns] = useState<Sign[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const params: Record<string, string> = {};
      if (searchStr) {
        params.description = searchStr;
      }
      if (sizeStr) {
        params.size = sizeStr;
      }
      if (stockStr) {
        params.stock = stockStr;
      }

      const response = await axios.get<Sign[]>(
        `${auth.REACT_APP_SERVER_API_URL}/${storeId}/signs/get-details`,
        {
          params,
          headers: {
            "Authorization": `Bearer ${secureLocalStorage.getItem("access_token")}`
          }
        }
      );
      setSigns(response.data);
    } catch (error) {
      setError("Error fetching signs");
    } finally {
      setIsSearch(true)
      setLoading(false)
    }
  };

  const handleReset = () => {
    setSearchStr("");
    setSizeStr("");
    setStockStr("");
    setSigns([]);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="my-[10px]">
          <div className="flex flex-row justify-center items-center">
            <div className="flex gap-1 border-t border-t-[#CCC] text-[#666] bg-[#D8D8D8]">
              <div className="flex items-center font-bold p-1 font-sm">
                <b>Search Signs</b>
              </div>
              <div className="flex items-center font-bold p-1 font-sm">
                <input
                  type="text"
                  name="search_str"
                  value={searchStr}
                  onChange={(e) => setSearchStr(e.target.value)}
                  className="border border-[#aaa] border-l-[#777] border-r-[#777] m-[2px] p-[3px] focus:outline-0 bg-white"
                  style={{ background: `url(images/input_shadow.png) no-repeat` }}
                />
              </div>
              <div className="flex items-center font-bold p-1 font-sm">
                <b>Filter Signs by</b>
              </div>
              <div className="flex items-center font-bold p-1 font-sm">
                <select
                  name="size_str"
                  value={sizeStr}
                  onChange={(e) => setSizeStr(e.target.value)}
                  className="border border-[#aaa] border-l-[#777] border-r-[#777] m-[2px] p-[3px] focus:outline-0 bg-white"
                  style={{ background: `url(images/input_shadow.png) no-repeat` }}
                >
                  <option value="">-Size-</option>
                  <option value="2x4">2x4</option>
                  <option value="4x5">4x5</option>
                  <option value="8.5x11">8.5x11</option>
                </select>
                <select
                  name="stock_str"
                  value={stockStr}
                  onChange={(e) => setStockStr(e.target.value)}
                  className="border border-[#aaa] border-l-[#777] border-r-[#777] m-[2px] p-[3px] focus:outline-0 bg-white"
                  style={{ background: `url(images/input_shadow.png) no-repeat` }}
                >
                  <option value="">-Stock-</option>
                  <option value="F Label">F Label</option>
                  <option value="Form A">Form A</option>
                  <option value="Legal">Legal</option>
                  <option value="Plain Paper">Plain Paper</option>
                </select>
              </div>
              <div className="flex items-center font-bold p-1 font-sm">
                <input
                  type="submit"
                  name="submit"
                  value="Go"
                  className="border-[3px] border-double border-[#999] border-l-[#CCC] border-t-[#CCC] text-[#333] font-bold text-sm m-[2px] p-[2px] bg-[#FAFAFA]"
                  style={{ background: `url(images/button.png) no-repeat` }}
                />
              </div>
              <div className="flex items-center font-bold p-1 font-sm">
                <button
                  type="button"
                  onClick={handleReset}
                  className="border-[3px] border-double border-[#999] border-l-[#CCC] border-t-[#CCC] text-[#333] font-bold text-sm m-[2px] p-[2px] bg-[#FAFAFA]"
                  style={{ background: `url(images/button.png) no-repeat` }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {!loading && !error && (
          <TableStd
            noBorder={true}
            titles={
              isSearch === true
                ? [
                  { title: "Sign Description", align: "left" },
                  { title: "Size" },
                  { title: "Sign Stock" },
                  { title: "Placement" },
                  { title: "Expires" },
                  { title: "Viewed?", width: 50 },
                ]
                : []
            }
          >
            {isSearch === true && signs.length === 0 ? (
              <NoSignsFound message="Sorry. Your search didn't return any matches." colSpan={6} />
            ) : (
              signs.map((sign, index) => (
                <tr
                  key={index}
                  className={`${sign.expirationDate === "Permanent" ? `` : `bg-red-50`} text-sm`}
                >
                  <td className="border-b border-b-[#CCC] px-1 py-[2px] flex items-center">
                    {sign.expirationDate === "Permanent" ? (
                      ""
                    ) : (
                      <img src={ImgNewBlink} alt="New" className="mr-2" />
                    )}
                    <a href={sign.link} onClick={() => {
                      fetch(`${auth.REACT_APP_SERVER_API_URL}/get-storage-file?fileName=${sign.link}`, {
                        method: 'GET',
                        headers: {
                          'Authorization': `Bearer ${secureLocalStorage.getItem("access_token")}`,
                        }
                      })
                        .then(response => {
                          if (!response.ok) throw new Error('Network response was not ok');
                          const updatedSigns = signs.map(value =>
                            value.link === sign.link && value.description === sign.description
                              ? { ...value, isClicked: true }
                              : value
                          );
                          setSigns(updatedSigns);
                          return response.blob();
                        })
                        .then(blob => {
                          const blobUrl = URL.createObjectURL(blob);
                          window.open(blobUrl, '_blank');
                        })
                        .catch(error => console.error('There was a problem with the fetch operation:', error));
                    }} target="_blank" rel="noopener noreferrer" className="text-blue-600 no-underline">
                      {sign.description}
                    </a>
                  </td>
                  <td align="center" className="border-b border-b-[#CCC] px-1 py-[2px]">
                    {sign.size}
                  </td>
                  <td align="center" className="border-b border-b-[#CCC] px-1 py-[2px]">
                    {sign.stock}
                  </td>
                  <td align="center" className="border-b border-b-[#CCC] px-1 py-[2px]">
                    {sign.placement}
                  </td>
                  <td align="center" className="border-b border-b-[#CCC] px-1 py-[2px]">
                    {sign.expirationDate}
                  </td>
                  <td align="center" className="border-b border-b-[#CCC] px-1 py-[2px]">
                    <img src={sign.isClicked ? ImgPrintYes : ImgPrintNo} alt="" />
                  </td>
                </tr>
              ))
            )}
          </TableStd>
        )}
      </div>
    </>
  );
};

export default SearchForm;
